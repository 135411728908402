import React from "react";
import { Link } from "react-scroll";

export const NavItem = ({ url, linkTo, label, onClick }) => {
  return url ? (
    <a className="nav-item" target="_blank" href={url}>
      {label}
    </a>
  ) : (
    <Link
      className="nav-item"
      activeClass="active"
      to={linkTo}
      spy={true}
      smooth={true}
      duration={500}
      offset={-75}
      onClick={onClick}
    >
      {label}
    </Link>
  );
};
