import logo from "./logo.svg";
import "./App.scss";
import { NavBar } from "./components/Nav/NavBar";
import Hero from "./sections/Hero";
import { About } from "./sections/About";
import { ContactUs } from "./sections/ContactUs";
import { Vision } from "./sections/Vision";
import { Element } from "react-scroll";
import { Trade } from "./sections/Trade";
import { Products } from "./sections/Products";
import { Team } from "./sections/Team";

function App() {
  return (
    <Element name="home" className="App">
      <NavBar />
      <Hero />
      <About />
      <Team />
      <Trade />
      <Products />
      {/* <Vision /> */}
      <ContactUs />
    </Element>
  );
}

export default App;
