import React from "react";
import { Paragraph } from "../../components/Paragraph";
import { Section } from "../../components/Section";

import "./trade.scss";

export const Trade = () => {
  return (
    <Section anchor="trade" title="Trade Finance" className="trade-container">
      <Paragraph>
        As a global trader we are fully cognisant of the grave importance of
        taking grip of your cash-flow and ensuring sufficient liquidity for all
        trades in hand and to be in control. Trading internationally can be
        risky business, with further distance for goods to travel, longer credit
        terms to contend with and negotiations with customers and suppliers in
        often unfamiliar markets.
      </Paragraph>
      <Paragraph>
        We can provide both exporters and importers with financial support and
        guidance to help make the process as straightforward as possible,
        keeping cash flowing and providing security to safeguard your business.
      </Paragraph>
      <Paragraph>
        A trade finance solution could be used when dealing with a new customer
        / new country to ensure the seller receives payment from the purchaser
        before an ongoing trading relationship has been established. Typically
        if you are selling a product for export you can obtain a letter of
        credit confirmed by a “good” national bank in the other country – this
        means that should your buyer fail to pay then the bank will.
      </Paragraph>
      <Paragraph>
        Trade Finance can also be preferred by the purchaser, through the use of
        documentary letters of credit, to ensure the goods they are purchasing
        are correctly packed / certified, often with inspection by a third party
        at point of loading.
      </Paragraph>
      <Paragraph>
        Some of the other solutions available include pre-shipment finance,
        working capital loans, overdrafts and receivables finance. To ensure
        payment is received, instruments such as bills of exchange and standby
        letters of credit can be requested by the seller. These can then be
        confirmed or even negotiated by their bank.
      </Paragraph>
      <Paragraph>
        Some clients implement a joined up approach to their trade finance –
        they import product with a letter of credit (normally secured against
        property) which then turns into a stock loan (secured against the stock
        and/or property) and then if the product is sold on trade credit terms
        an invoice finance facility provides working capital (secured against
        the debtor book) back to the bank account whilst payment is outstanding.
        Because of the range of products and scenarios, this is priced on a
        bespoke basis.
      </Paragraph>
      <Paragraph>
        For the right transaction and partnership, we are able to provide trade
        finance solutions. If you would like to know more about trade finance.
        Please contact us.
      </Paragraph>
      <Paragraph>
        Trade Finance is an umbrella term that covers a variety of financial
        techniques and instruments used by importers and exporters. These
        include letters of credit (LCs),standby Letters of credit (SBLC) bonds,
        guarantees, purchase order finance, stock finance, structured commodity
        finance, and invoice finance (discounting and factoring).
      </Paragraph>
    </Section>
  );
};
