import React, { useState } from "react";

import "./products.scss";

import down from "./down-chevron.png";
import right from "./right-chevron.png";

export const Product = ({ title, items = [] }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="product-card">
      <div
        className="title"
        onClick={() => items.length > 0 && setExpanded(!expanded)}
      >
        <div className="title-text"> {title}</div>{" "}
        {items.length > 0 ? (
          <img className="icon" src={expanded ? down : right} />
        ) : null}
      </div>
      {expanded ? (
        <div className="items">
          <ul>
            {items.map((i) => (
              <li>{i}</li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
