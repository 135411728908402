import React from "react";
import { Paragraph } from "../../components/Paragraph";
import { Section } from "../../components/Section";
import { Product } from "./Product";
import products from "./products-data.json";

import "./products.scss";

export const Products = () => {
  return (
    <Section anchor="products" title="Products" className="products-container">
      <Paragraph>
        {products.map((product) => (
          <Product {...product} />
        ))}
      </Paragraph>
    </Section>
  );
};
