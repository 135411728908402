import React, { useState } from "react";
import { Logo } from "../Logo";
import "./nav.scss";
import { NavItem } from "./NavItem";

import close from "./close.png";
import menu from "./menu.png";

export const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <div className="navbar">
        <div className="left">
          <Logo />
        </div>
        <div className="right nav-items hidden-sm hidden-xs hidden-md">
          <NavItem linkTo="home" label="Home" url="" />
          <NavItem linkTo="about" label="About Us" url="" />
          <NavItem linkTo="trade" label="Trade Finance" url="" />
          <NavItem linkTo="products" label="Products" url="" />
          <NavItem
            linkTo="autp"
            label="Automobile Division"
            url="https://autotrakme.com/"
          />
          <NavItem
            linkTo="partnership["
            label="Intrinsic Partnership (WA)"
            url="https://waoilfactory.com/about-us/"
          />
          <NavItem linkTo="gallery" label="Gallery" url="" />
          <NavItem linkTo="contact" label="Contact" url="" />
        </div>
        <div
          className="right visible-xs "
          onClick={() => setShowMenu(!showMenu)}
        >
          <img
            className="menu-icon"
            src={showMenu ? close : menu}
            alt="show or hide menu"
          />
        </div>
        <div
          className="right menu-text visible-sm   visible-md"
          onClick={() => setShowMenu(!showMenu)}
        >
          {showMenu ? "Close" : "Menu"}
        </div>
      </div>
      {showMenu ? (
        <div className="dropdown">
          <NavItem
            onClick={() => setShowMenu(!showMenu)}
            linkTo="home"
            label="Home"
            url=""
          />
          <NavItem
            onClick={() => setShowMenu(!showMenu)}
            linkTo="about"
            label="About Us"
            url=""
          />
          <NavItem
            onClick={() => setShowMenu(!showMenu)}
            linkTo="trade"
            label="Trade Finance"
            url=""
          />
          <NavItem
            onClick={() => setShowMenu(!showMenu)}
            linkTo="trade"
            label="Products"
            url=""
          />
          <NavItem
            onClick={() => setShowMenu(!showMenu)}
            linkTo="autp"
            label="Automobile Division"
            url=""
          />
          <NavItem
            onClick={() => setShowMenu(!showMenu)}
            linkTo="partnership["
            label="Intrinsic Partnership (WA)"
            url=""
          />
          <NavItem
            onClick={() => setShowMenu(!showMenu)}
            linkTo="gallery"
            label="Gallery"
            url=""
          />
          <NavItem
            onClick={() => setShowMenu(!showMenu)}
            linkTo="contact"
            label="Contact"
            url=""
          />
        </div>
      ) : null}
    </>
  );
};
