import React from "react";
import { Element } from "react-scroll";
import { SectionHeader } from "../SectionHeader";

import "./section.scss";

export const Section = ({ anchor, title, className, children }) => {
  return (
    <Element name={anchor} className={className}>
      <SectionHeader>{title}</SectionHeader>
      {children}
    </Element>
  );
};
