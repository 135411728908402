import React from "react";
import { Paragraph } from "../../components/Paragraph";
import { Section } from "../../components/Section";
import "./team.scss";

import data from "./team-data.json";
import { Member } from "./Member";

export const Team = () => {
  return (
    <Section anchor="mission" title="Team" className="team-section">
      <Paragraph className="team-container">
        {data.map((member) => (
          <Member {...member} />
        ))}
      </Paragraph>
    </Section>
  );
};
