import React from "react";
import { Paragraph } from "../../components/Paragraph";
import { Section } from "../../components/Section";

import "./about.scss";

export const About = () => {
  return (
    <Section anchor="about" title="" className="about-container">
      <h2>Top Score – TRUSTED TRADE! BEYOND BORDERS!</h2>
      <Paragraph>
        Top Score is an international conglomerate operating from multiple
        countries. A conception by a team of passionate executives, with a
        distinctive blend of entrepreneurial, operational experience, and renown
        for the team’s immaculate track record of meeting growing global chain
        of demand for commodities between nations who can supply and who in turn
        needs other products. Hence, we connect the dots in world map between
        buyers and sellers. Trusted trade beyond borders.
      </Paragraph>
      <Paragraph>
        We are here to build upon lasting relationships, to embark on innovative
        solutions using the most modernistic platforms to make a difference in
        the supply and demand chain. Our team centremost approach is to leverage
        upon our global experience, market awareness, and global network to
        enhance superior value balance in the marketplace.
      </Paragraph>
      <Paragraph>
        The backroom of Top Score comprises of ultra-net-worth individual
        investors, reputable entrepreneurs, prolific investors and energetic
        traders. They are supported by logistics, finance, and legal experts.
      </Paragraph>
      <h3>Cenrtic Value </h3>
      <Paragraph>
        Top Score is a unified trading company centred globally. Our
        contemporary service trades only the finest of products and commodities
        to both end-users and substantial traders. Our international experts
        handle commodity trade from a central base; from Asia, Middles East,
        Europe and Africa. As an interconnected trading and finance partner, we
        centre our operations from the international financial and global
        connecting hub. We aspire to be reliable, transparent and never
        relinquishing until the disparity between the supply and demand
        equilibrium is optimised. We strive to provide a specialised service
        catered to our clients in our endeavour to move products from its source
        to where it is needed. We can do this with great efficiency, as our team
        have the in-depth knowledge to discover disproportions in product
        availability and utilising our global expertise.
      </Paragraph>
      <h3>Journey </h3>
      <Paragraph>
        We aspire to optimize timeframe arbitrage in our ability to amplify the
        contrarian approach in the marketplace for the benefit of our trading
        partners. We are here for the long haul and our philosophy is built upon
        aligning our interest with our clients. We challenge ourselves and each
        other to drive positive change in the markets.
      </Paragraph>
      <h3>Culture </h3>
      <Paragraph>
        Our culture empowers exceptional people to become the best version of
        themselves
      </Paragraph>
      <h3>Excellence </h3>
      <Paragraph>
        Our principle governance is trust, transparency, tenacity and tediously
        attempting to exceed expectations of our stakeholders
      </Paragraph>
      <h3>Creativity </h3>
      <Paragraph>
        It is our intent to craft an environment where everyone is given the
        opportunity to excel. Our policy is a flat communication hierarchy that
        allows us to openly and honestly challenge each other’s reasoning and
        logic. This ensures the finest ideas is elated and innovation thrives.
      </Paragraph>
    </Section>
  );
};
