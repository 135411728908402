import React from "react";
import "./hero.scss";

// import heroImage from "./hero2.webp";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-text">
        <div>Trusted Trade!</div>
        <div>Beyond Borders!</div>
      </div>
      {/* {heroImage} */}
    </div>
  );
};

export default Hero;
