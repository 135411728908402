import React from "react";
import "./team.scss";

import w from "./w.png";
import e from "./e.png";
import p from "./p.png";

const icons = {
  whatsapp: w,
  phone: p,
  email: e,
};

export const Member = ({ name, role, contacts }) => {
  return (
    <div className="contact-card">
      <div className="name">{name}</div>
      <div className="role">{role}</div>
      <div className="contact-mode">
        {contacts.map((contact) => (
          <div className={contact.type}>
            <img className="icon" src={icons[contact.type]}></img>
            <span>
              {contact.country ? `(${contact.country}) ` : ""} {contact.value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
