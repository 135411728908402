import React from "react";
import { Section } from "../../components/Section";
import "./contactUs.scss";

export const ContactUs = () => {
  return (
    <Section anchor="contact" title="Contact Us" className="contact-us">
      <p>For all enquiries kindly contact:</p>
      <br />
      <div class="address">
        <p>PO box 282249</p>
        <p>6th street, Al Quos Ind. Area 1</p>
        <p>Near 3rd Interchange</p>
        <p>Dubai</p>
        <p>UAE</p>
        <br />
        <p>
          Tel: <a href="tel:+971 4 338 4007">+971 4 338 4007</a>
        </p>
        <p>
          Fax : <a href="fax:+971 4 338 4005">+971 4 338 4005</a>
        </p>
        <br />
        <p>
          Email:
          <a href="mailto:info@topscoregroup.com"> info@topscoregroup.com</a>
        </p>
      </div>
    </Section>
  );
};
